<template>
	<div class="status__container">
		<div class="loader"></div>
	</div>
</template>

<style>
	.loader {
		border: 10px solid #f3f3f3;
		border-radius: 50%;
		border-top: 10px solid #333;
		width: 100px;
		height: 100px;
		-webkit-animation: spin 2s linear infinite;
		animation: spin 2s linear infinite;
	}

	@-webkit-keyframes spin {
		0% { -webkit-transform: rotate(0deg); }
		100% { -webkit-transform: rotate(360deg); }
	}

	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
</style>